.Content {
  padding: 2rem 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ContentLeft {
  display: inline-flex;
  flex-direction: column;
  width: var(--small-block);
}

.ContentMain {
  display: inline-flex;
  flex-direction: column;
  width: var(--large-block);
  padding: 0rem 2rem;
}

.ContentRight {
  display: inline-flex;
  flex-direction: column;
  width: var(--small-block);
}
