.Component {
  background-color: lightgray;
  padding: 0.5rem 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Content {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: var(--large-block);
  padding: 0rem 2rem;
}

.Navbar {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.Navbar li {
  display: flex;
  align-items: center;
  margin: 0rem 0rem 0rem 1rem;
}
