.Component {
  padding: 1rem 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: var(--large-block);
  padding: 0rem 2rem;
}
