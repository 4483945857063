hr {
  border: 0rem;
  margin: 1rem 0rem;
  border-top: 1px solid silver;
}

ul {
  list-style-type: circle;
  list-style-position: outside;
  padding-left: 2rem;
}

/* START Coursework table styling */
.Coursework {
  table-layout: fixed;
  border-collapse: collapse;
  width: var(--large-block);
  border: solid;
}

.Coursework thead tr {
  border: solid;
  background-color: lightgray;
  border-bottom-width: 1px;
}

.Coursework thead th:nth-child(n + 2) {
  text-align: left;
}

.Coursework tbody td {
  padding: 0.5rem 0rem;
}

.Coursework thead th:nth-child(-n + 2) {
  width: 20%;
}

.Coursework tbody:nth-child(odd) {
  background-color: whitesmoke;
}

.Coursework tbody:nth-child(even) {
  background-color: white;
}
/* END Coursework table styling */
