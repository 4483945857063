:root {
  /* Absolute Spacing */
  --no-space: 0rem;
  --small-space: 1rem;
  --medium-space: 2rem;
  --large-space: 4rem;
  --small-block: 16rem;
  --medium-block: 32rem;
  --large-block: 48rem;

  /* Relative Spacing */

  /* Background Color */
  --primary: red; /* Can include light and dark variants of primary and secondary */
  --primary-variant: orange;
  --secondary: yellow;
  --secondary-variant: green;
  --background: blue;
  --surface: purple;
  --error: brown;

  /* Text color */
  --on-primary: pink;
  --on-secondary: gray;
  --on-background: brown;
  --on-surface: white;
  --on-error: black;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
table,
tr,
th,
td,
link,
ul,
ol,
li,
input {
  margin: 0%;
  padding: 0%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

a:visited {
  color: rgb(0, 0, 238);
}

a:hover {
  color: crimson;
}

h1 {
  font-weight: 500;
}

h2 {
  font-weight: 400;
}
